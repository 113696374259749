<template>
  <v-card v-if="!debtListFormatted.length && loadPage">
    <v-sheet
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="text-field, table, data-table"
      >
      </v-skeleton-loader>
    </v-sheet>
  </v-card>
  <v-card v-else>
    <v-card-title class="text-uppercase font-weight-bold">
      Liste des factures
    </v-card-title>
    <v-divider></v-divider>
    <br>
    <div class="mx-auto">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            offset-md="8"
            md="4"
          >
            <v-text-field
              v-model="search"
              :append-icon="icon.mdiMagnify"
              label="Rechercher"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Table -->
      <v-data-table
        id="list-refill"
        :headers="headers"
        :items="debtListFormatted"
        :search="search"
        :items-per-page="-1"

        divider="true"
        class="table-kitchen-sink mx-auto font-weight-bold"
      >
        <template #[`item.num_debt`]="{item}">
          <div class="d-flex justify-center p-5">
            <div class="d-flex flex-column ms-3 ">
              <span class="font-weight-semibold text-body-1 text--primary"> {{ item.num_debt }} </span>
            </div>
          </div>
        </template>

        <template #[`item.month`]="{item}">
          <div class="d-flex justify-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block text-truncate text-uppercase text--primary"><span class="font-weight-semibold"> {{ litteralMonth(item.month) }} {{ item.year }} </span></span>
            </div>
          </div>
        </template>

        <template #[`item.commitment_date`]="{item}">
          <div class="d-flex justify-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-bold text-truncate text--primary"> {{ item.commitment_date == null ? "Non défini":formatDate(item.commitment_date) }} </span>
            </div>
          </div>
        </template>

        <template #[`item.amount`]="{item}">
          <div class="d-flex justify-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block text-truncate font-weight-semibold text--primary"> {{ withCurrency(item.amount) }} </span>
            </div>
          </div>
        </template>

        <template #[`item.state`]="{item}">
          <div class="d-flex justify-center">
            <div class="d-flex flex-column ms-3">
              <v-chip
                v-if="item.state===1"
                class="ma-2 text-center pr-5 pl-5"
                color="#F1FFE8FF"
              >
                <b class="success--text">Payé</b>
              </v-chip>
              <v-chip
                v-else
                class="ma-2 text-center"
                color="#FFF6ECFF"
              >
                <b class="custom-warning--text">En instance</b>
              </v-chip>
            </div>
          </div>
        </template>

        <template #[`item.actions`]="{ item }">
          <div
            class="col-12"
          >
<!--            <v-btn
              v-if="item.state!==1 && item.commitment_date!==null && isCurrentTime(item.month) "
              small
              color="primary"
              @click="gotoDebtForm(item.num_debt, item.amount)"
            >
              Payer
            </v-btn>
            <v-btn
              v-else-if="item.commitment_date===null && isPostpayeContrat()"
              small
              color="primary"
              class="ms-2"
              @click="dialog=true"
            >
              Engager le paiement
            </v-btn>-->

            <v-btn
              class="ml-1"
              outlined
              small
              color="primary"
              @click="gotoEtatDette(item.month, item.year,item.id)"
            >
              Détail
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
      outlined
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 text-center">Délai de paiement</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="8"
                offset="2"
              >
                <v-menu
                  v-model="commitment.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="commitment.date"
                      label="Délai de paiement"
                      placeholder="Selectionner votre délai de paiement"
                      :readonly="true"
                      v-bind="attrs"
                      outlined
                      dense
                      autofocus
                      v-on="on"
                    >
                      <template v-slot:append>
                        <v-icon>{{ icon.mdiCalendarMonth }}</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="commitment.date"
                    color="primary"
                    :min="maxDate.debut"
                    :max="maxDate.fin"
                    locale="fr-fr"
                    @input="commitment.menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDialog"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loading"
            @click="setPaymentDate"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { computed, onBeforeMount, ref } from '@vue/composition-api'
import {
  mdiFileDocumentOutline,
  mdiMagnify,
  mdiHistory,
  mdiMenuDown,
} from '@mdi/js'
import Http from '@/helpers/http'
import moment from 'moment/moment'
import { useRouter } from '@core/utils'
import useDynamicConstant from '@/helpers/useDynamicConstant'
import { isPostpayeContrat, isPostpayeType } from '@/helpers/constants/contrat'
import Vue from 'vue'
Vue.prototype.moment = moment
export default {
  methods: { isPostpayeContrat },
  setup() {
    const search = ref('')
    const debtList = ref([])
    const itemPerPage = 15


    const commitment = ref({
      date: '',
      menu: false,
    })
    const { router } = useRouter()

    const loading = ref(false)
    const loadPage = ref(true)

    const contrat = JSON.parse(localStorage.getItem('defaultContrat'))
    const dialog = ref(false)

    const loadDebtList = () => {
      Http.get(
        `list-customer-segment-debt/${contrat.id}`,
      ).then(rep => {
        console.log('Réponse : ')
        console.log(rep.data)
        debtList.value = rep.data
        loadPage.value = false
      }).catch(error => {
        console.error('Erreur :', error)
      })
    }

    const gotoEtatDette = (monthNumber, year,id) => {
      const startOfMonth = moment([year, monthNumber - 1]).format('YYYY-MM-DD')
      const endOfMonth = moment(startOfMonth).endOf('month').format('YYYY-MM-DD')
      const periode = { Debut: startOfMonth, Fin: endOfMonth }
      localStorage.setItem('periode', JSON.stringify(periode))
      router.push({ name: 'etat-dette', params: { id } })
    }

    const gotoDebtForm = (billNumber, billAmount) => {
      router.push({ name: 'remboursement-dette', params: { billNumber, billAmount } })
    }

    const closeDialog = () => {
      dialog.value = false
      commitment.value.date = ''
    }

    const currentDate = moment().format('YYYY-MM-DD')
    const maxDate = ref({})

    const maxDateOfPayment = () => {
      if (isPostpayeContrat()) {
        Http.get(`payment-date-period/${contrat.pivot.customer_segment_id}`)
          // eslint-disable-next-line consistent-return
          .then(response => {
            if (response.status === 200) {
              console.log(response.data)
              maxDate.value = response.data
              if (moment(currentDate).diff(maxDate.value.fin, 'days') >= 0) {
                // eslint-disable-next-line no-multi-assign
                maxDate.value.debut = maxDate.value.fin = currentDate
              }
            }
          })
          .catch(error => {
            console.error(error)
            maxDate.value = { debut: moment().format('YYYY-MM-DD'), fin: moment().format('YYYY-MM-DD') }
          })
      }
    }

    const debtListFormatted = computed(() => debtList.value)

    const litteralMonth = monthNumber => moment().month((monthNumber - 1)).format('MMMM')

    const isCurrentTime = monthNumber => (moment(currentDate).month() + 1) === monthNumber

    const formatDate = date => moment(date).format('DD/MM/YYYY')

    const { CURRENCY } = useDynamicConstant()

    const currency = ref(CURRENCY)
    const withCurrency = price => `${Number(price).toLocaleString()} ${CURRENCY}`

    const setPaymentDate = () => {
      loading.value = true
      const params = { paymentDate: commitment.value.date, commitmentDate: currentDate }
      Http.post(`set-payment-date/${contrat.pivot.customer_segment_id}`, params)
        .then(response => {
          console.log(response.data)
          if (response.status === 201) {
            console.info('engagement du paiement')
          }
        })
        .catch(error => { console.error(error) })

      setTimeout(() => {
        closeDialog()
        loading.value = false
      }, 3000)
    }

    onBeforeMount(() => {
      loadDebtList()
      maxDateOfPayment()
    })

    return {
      icon: {
        mdiFileDocumentOutline,
        mdiMagnify,
        mdiHistory,
        mdiMenuDown,
      },
      search,
      itemPerPage,
      loadDebtList,
      debtListFormatted,
      litteralMonth,
      formatDate,
      currency,
      withCurrency,
      commitment,
      isCurrentTime,
      loadPage,

      dialog,
      closeDialog,
      loading,

      gotoEtatDette,
      gotoDebtForm,
      maxDateOfPayment,
      maxDate,
      setPaymentDate,

      headers: [
        {
          text: 'Numéro', value: 'num_debt', divider: true, align: 'center',
        },
        {
          text: 'Mois', value: 'month', divider: true, align: 'center',
        },
        {
          text: 'Délai de paiement', value: 'commitment_date', divider: true, align: 'center',
        },
        {
          text: 'Montant', value: 'amount', divider: true, align: 'center',
        },
        {
          text: 'Etat', value: 'state', divider: true, align: 'center',
        },
        {
          text: 'Actions', value: 'actions', sortable: false, divider: true, align: 'center',
        },
      ],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    }
  },
  watch: {
  },
  mounted() {
    // this.refillList = JSON.parse(JSON.stringify(data))
  },
  created() {
    moment.locale('fr')
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/apps/refill.scss';

.custom--icon{
  height: 15px !important;
  width: 15px !important;
  color: #1E9FF2 !important;
}

.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.border{
  border: #0c5460 solid 1px;
}

.custom-warning--text{
  color: darkorange;
  //background-color: #ffcfcf;
}

.custom-btn:hover{
  background-color: transparent !important;
}
</style>
